
// Default variable overrides
$primary: #348e38;
$secondary: #525368;
$light: #E8F5E9;
$dark: #0F4229;

$body-color: $secondary;

$font-family-base: 'Open Sans', sans-serif;

$headings-font-family: 'Jost', sans-serif;
$headings-color: $dark;
$headings-font-weight: 700;
$display-font-weight: 700;
$enable-responsive-font-sizes: true;

$border-radius: 4px;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;

$link-decoration: none;

$enable-negative-margins: true;
    
// Main bootstrap scss files & icons
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";


// Font Awsome Free Icons
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";

// Extra css for leaflet and leaflet plugins
@import "../../node_modules/leaflet/dist/leaflet.css";
@import "../../node_modules/leaflet-extra-markers/src/assets/css/leaflet.extra-markers.css";
@import "../../node_modules/leaflet.control.layers.tree/L.Control.Layers.Tree.css";
@import "../lib/leaflet-sidepanel/leaflet-sidepanel.css";
@import "../../node_modules/leaflet.locatecontrol/dist/L.Control.Locate.css";

.vh-80 {
    height: 80vh !important;
}

.vh-75 {
    height: 75vh !important;
}

.vh-60 {
    height: 60vh !important;
}

:root {
    --primary: #348E38;
    --secondary: #525368;
    --light: #E8F5E9;
    --dark: #0F4229;
}

// custom sytle for the main page carousel
.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(15, 66, 41, .6);
    z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
    width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    background-color: var(--primary);
    border: 10px solid var(--primary);
}

@media (max-width: 768px) {
    #header-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #header-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    #main-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #main-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    #second-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #second-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    #hrtg-carousel .carousel-item {
        position: relative;
        min-height: 450px;
    }
    
    #hrtg-carousel .carousel-item img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
// Nearby Items Carousel
#nearby-items-carousel .carousel-inner {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

#nearby-items-carousel .carousel-control-prev {
    width: 15%;
    // margin-left: -30px;
}

#nearby-items-carousel .carousel-control-next {
    width: 15%;
    // margin-right: -30px;
}

#nearby-items-carousel .carousel-control-prev-icon,
#nearby-items-carousel .carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 0rem;
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

#nearby-items-carousel {
    height: 3rem;
}

#nearby-items-carousel .carousel-item {
    position: relative;
}

#nearby-items-carousel.carousel-item .card {
    position: relative;
    width: 70%;
    height: 3rem;
    // object-fit: cover;
}



// end of carousel customisations


h1,
.h1,
h2,
.h2,
.fw-bold {
    font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
    font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
    font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}

/*** Button ***/
.btn {
    transition: .5s;
    font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
    color: var(--light);
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
}


/*** Navbar ***/
.navbar.sticky-top {
    top: -100px;
    transition: .5s;
}

.navbar .navbar-brand,
.navbar a.btn {
    height: 80px
}

.navbar .navbar-nav .nav-link {
    margin-right: 35px;
    padding: 25px 0;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
    outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
    color: var(--primary);
}

.navbar .dropdown-toggle::after {
    border: none;
    content: "\F282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link  {
        margin-right: 0;
        padding: 10px 0;
    }

    .navbar .navbar-nav {
        border-top: 1px solid #EEEEEE;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}


/*** Back to Top Button, defined in page-components, footer ***/
.back-to-top {
    position: fixed;
    display: none;
    right: 30px;
    bottom: 30px;
    z-index: 99;
}

/*** Header ***/
.page-header {
    background: linear-gradient(rgba(15, 66, 41, .6), rgba(15, 66, 41, .6)), url(../img/view-langleyburell.jpg) center center no-repeat;
    background-size: cover;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
    color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
    font-size: 18px;
    color: var(--light);
}


/*** Contact Form ***/
.contact-form .help-block ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/*** Facts & Quote ***/
.facts,
.quote {
    background: rgba(15, 66, 41, .6);
}



/*** Footer ***/
.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: var(--light);
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\F285";
    font-family: "bootstrap-icons";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: var(--primary);
    letter-spacing: 1px;
    box-shadow: none;
}

.copyright {
    color: var(--light);
    background: #072A19;
}

.copyright a {
    color: #FFFFFF;
}

.copyright a:hover {
    color: var(--primary);
}

/*** Heritage and Walks  ***/
#heritage-filters {
    display: inline-block;
    background: var(--light);
    padding: 10px 15px;
}

#heritage-filters li {
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#heritage-filters li:hover,
#heritage-filters li.active {
    color: var(--dark);
    border-color: var(--dark);
}

.heritage-item {
    position: relative;
    text-align: center;
}

.heritage-item .heritage-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
}

.heritage-item .heritage-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    transform: scale(1.2);
    transition: .3s;
    z-index: -1;
}

.heritage-item:hover .heritage-img img {
    transform: scale(1);
}

.heritage-item .heritage-text {
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .3s;
}

.heritage-item:hover .heritage-text {
    background: rgba(15, 66, 41, .6);
}

.heritage-item .heritage-text h4,
.heritage-item .heritage-text h6,
.heritage-item .heritage-text p {
    transition: .3;
}

.heritage-item .heritage-text p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
}

.heritage-item:hover .heritage-text h4,
.heritage-item:hover .heritage-text h6 {
    color: #FFFFFF;
}

.heritage-item:hover .heritage-text p {
    color: var(--light);
}

.heritage-item .heritage-text .btn-square {
    width: 100px;
    height: 100px;
    max-height: 100px;
    background: transparent;
    transition: .5s;
}

.heritage-item:hover .heritage-text .btn-square {
    background: var(--light);
}

.heritage-item .heritage-text .btn {
    width: 31px;
    height: 31px;
    display: inline-flex;
    align-items: center;
    color: var(--dark);
    background: var(--light);
    white-space: nowrap;
    overflow: hidden;
    transition: .3s;
}

.heritage-item:hover .heritage-text .btn {
    width: 112px;
}

.nearby-item a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}



#walks-filters {
    display: inline-block;
    background: var(--light);
    padding: 10px 15px;
}

#walks-filters li {
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#walks-filters li:hover,
#walks-filters li.active {
    color: var(--dark);
    border-color: var(--dark);
}

.walks-text-hazard {
    color: red;
    font-weight: 700;
}


/*** Newsletter and Newsarticles  ***/
#newsarticles-filters {
    display: inline-block;
    background: var(--light);
    padding: 10px 15px;
}

#newsarticles-filters li {
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#newsarticles-filters li:hover,
#newsarticles-filters li.active {
    color: var(--dark);
    border-color: var(--dark);
}

.newsarticles-item {
    position: relative;
    text-align: left;
}

.newsarticles-text .dateCenter {
    text-align: center;
    margin-top: -9;
}

.newsarticles-text .dateAsHeading {
    margin-bottom: 0;
    text-decoration: underline;
}

.newsarticles-text p {
    margin-bottom: 12;
    margin-top: 0;
}

.newsarticles-text .last p {
    margin-bottom: 0;
    margin-top: 0;
}

.newsarticles-text li {
    margin-bottom: 0;
    margin-top: 0;
}

.newsarticles-text span.editionId {
    background-color: var(--light);
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    padding: 0.3rem;
    border-radius: 0.25rem;
    border: 2px solid var(--primary);
}

.newsarticles-item .newsarticle-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
}

.newsarticles-item .newsarticle-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    transform: scale(1.2);
    transition: .3s;
    z-index: -1;
}

.heritage-item:hover .heritage-img img {
    transform: scale(1);
}

.newsarticles-item .newsarticles-text {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, .08);
    transition: .3s;
}

// .newsarticles-item:hover .newsarticles-text {
//     background: rgba(15, 66, 41, .6);
// }

.newsarticles-item .newsarticles-text h4,
.newsarticles-item .newsarticles-text h6,
.newsarticles-item .newsarticles-text p {
    transition: .3;
}

// .newsarticles-item .newsarticles-text p {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 3; /* number of lines to show */
//             line-clamp: 3; 
//     -webkit-box-orient: vertical;
// }

// .newsarticles-item:hover .newsarticles-text h4,
// .newsarticles-item:hover .newsarticles-text h6 {
//     color: #FFFFFF;
// }

// .newsarticles-item:hover .newsarticles-text p {
//     color: var(--light);
// }

.newsarticles-item .newsarticles-text .btn-square {
    width: 100px;
    height: 100px;
    max-height: 100px;
    background: transparent;
    transition: .5s;
}

.newsarticles-item:hover .newsarticles-text .btn-square {
    background: var(--light);
}

.newsarticles-item .newsarticles-text .btn {
    width: 31px;
    height: 31px;
    display: inline-flex;
    align-items: left;
    color: var(--dark);
    background: var(--light);
    white-space: nowrap;
    overflow: hidden;
    transition: .3s;
}

.newsarticles-item:hover .newsarticles-text .btn {
    width: 112px;
}


/*** Neighbouthood Plan ***/

#np-edition-filters {
    display: inline-block;
    background: var(--light);
    padding: 10px 15px;
}

#np-edition-filters li {
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#np-edition-filters li:hover,
#np-edition-filters li.active {
    color: var(--dark);
    border-color: var(--dark);
}

#np-section-filters {
    display: inline-block;
    background: var(--light);
    padding: 10px 15px;
}

#np-section-filters li {
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#np-section-filters li:hover,
#np-section-filters li.active {
    color: var(--dark);
    border-color: var(--dark);
}

.np-item {
    position: relative;
    text-align: left;
}

.np-text .dateCenter {
    text-align: center;
    margin-top: -9;
}

.np-text .dateAsHeading {
    margin-bottom: 0;
    text-decoration: underline;
}

.np-text p {
    margin-bottom: 12;
    margin-top: 0;
}

.np-text .last p {
    margin-bottom: 0;
    margin-top: 0;
}

.np-text li {
    margin-bottom: 0;
    margin-top: 0;
}

.np-text span.editionId {
    background-color: var(--light);
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    padding: 0.3rem;
    border-radius: 0.25rem;
    border: 2px solid var(--primary);
}

.np-text span.sectionId {
    background-color: var(--light);
    display: inline-block;
    font-weight: 500;
    color: var(--primary);
    padding: 0.3rem;
    border-radius: 0.25rem;
    border: 2px solid var(--primary);
}

.np-item .np-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
}

.np-item .np-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    object-fit: cover;
    transform: scale(1.2);
    transition: .3s;
    z-index: -1;
}

.np-item:hover .np-img img {
    transform: scale(1);
}

.np-item .np-text {
    background: #FFFFFF;
    box-shadow: 0 0 20px rgba(0, 0, 0, .08);
    transition: .3s;
}

// .np-item:hover .np-text {
//     background: rgba(15, 66, 41, .6);
// }

.np-item .np-text h4,
.np-item .np-text h6,
.np-item .np-text p {
    transition: .3;
}

// .np-item .np-text p {
//     overflow: hidden;
//     text-overflow: ellipsis;
//     display: -webkit-box;
//     -webkit-line-clamp: 3; /* number of lines to show */
//             line-clamp: 3; 
//     -webkit-box-orient: vertical;
// }

// .np-item:hover .np-text h4,
// .np-item:hover .np-text h6 {
//     color: #FFFFFF;
// }

// .np-item:hover .np-text p {
//     color: var(--light);
// }

.np-item .np-text .btn-square {
    width: 100px;
    height: 100px;
    max-height: 100px;
    background: transparent;
    transition: .5s;
}

.np-item:hover .np-text .btn-square {
    background: var(--light);
}

.np-item .np-text .btn {
    width: 31px;
    height: 31px;
    display: inline-flex;
    align-items: left;
    color: var(--dark);
    background: var(--light);
    white-space: nowrap;
    overflow: hidden;
    transition: .3s;
}

.np-item:hover .np-text .btn {
    width: 112px;
}


/*** Gazetteer ***/
.gztr-side-container {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    overflow: hidden;
}
.gztr-side-frame {
    height: 100%;
    width: 100%;
  /*   We use outline over border as has issues in some cases */
   /* outline: 3px solid black; */
  }

.gztr-side .gztr-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: .5s;
    z-index: 3;
    opacity: 0;
}

/*** Gazetteer list items ***/
.gztr-item.active {
    color: var(--dark);
    border-color: var(--dark);
    background: rgba(15, 66, 41, .6);
}

.gztr-item.active .gztr-list-text {
    right: 0;
    opacity: 0.8;
}

.gztr-item li:hover,
.gztr-item li.active {
    color: var(--dark);
    border-color: var(--dark);
}

.gztr-list-item {
    position: relative;
    overflow: hidden;
}

.gztr-list-item .gztr-list-text {
    position: absolute;
    width: calc(100% - 25px);
    right: -100%;
    bottom: 15px;
    padding: 0.5rem;
    background: #FFFFFF;
    border-radius: 4px 0px 0px 4px;
    opacity: 0;
    transition: .5s;
}

.gztr-list-item:hover .gztr-list-text {
    right: 0;
    opacity: 0.8;
}

.gztr-list-text h6 {
    font-size: 1.5vw;
}

.map-container {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    display: block;
    /* Fallback for vmin */
    padding: 0px 1rem 1rem 1rem;
    padding: 0px 1vmin 1vmin 1vmin;
}
  
.map-frame {
    height: 100%;
    width: 100%;
  /*   We use outline over border as has issues in some cases */
    /* outline: 3px solid black; */
}
  
#lbht-map { 
    height: 75vh;
}
  
  /* 
  Support for browsers that DON'T support Flexbox uses 100% height on the map-container defined above.
  Map will at least render, for the small amount of browsers that DON'T support flexbox. Users will just have to scroll abit ¯\_(ツ)_/¯
  */
  @supports (display: flex) {
    .map-container {
      height: 0;
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
    }
  
    .map-frame {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
    }
  }

  /*
  leaflet css
  */
  .leaflet-control-layers-base {  text-align: left; }
  .leaflet-control-layers-overlays {  text-align: left; }

  /* These are used to reposition the Layer Control to take the .sidepanel size into account */
  .leaflet-anim-control-container.right-opened .leaflet-right {
    right: clamp(120px, 25vw, 400px);
    transition: right 0.5s ease, right 0.5s ease;
    }

    /* css to customize Leaflet default styles  */
    .poiPopupCustom .leaflet-popup-tip,
    .poiPpupCustom .leaflet-popup-content-wrapper {
        background: #f6f6f6;
        color: #234c5e;
    }

    .poiPopupCustom {
        width: 285px;
        max-width: 285px;
    }

    .poiPopupCustom img {
        max-width: 240px;
    }

    .walksPopupCustom .leaflet-popup-tip,
    .walksPopupCustom .leaflet-popup-content-wrapper {
        background: #f6f6f6;
        color: #234c5e;
    }

    .walksPopupCustom {
        width: 285px;
        max-width: 285px;
    }

    .walksPopupCustom img {
        max-width: 240px;
    }

    /* Audio Buttons and Timeline */    
    #pButton {
        float: left;
    }
      
    #timeline {
        width: 90%;
        height: 2px;
        margin-top: 20px;
        margin-left: 10px;
        float: left;
        -webkit-border-radius: 15px;
        border-radius: 15px;
        background: rgba(0, 0, 0, 0.3);
    }
    
    #pButton {
        margin-top: 12px;
        cursor: pointer;
    }
    
    #playhead {
        width: 8px;
        height: 8px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        margin-top: -3px;
        background: black;
        cursor: pointer;
    }


    /* Leaflet sidepanel plugin */
    /* sidepanel within Gazetteer Map */

    /* This needs to match the '.leaflet-anim-control-container.right-opened' above */
    .sidepanel {
        width: clamp(120px, 25vw, 400px);
        height: 100%;
    }

    .sidepanel.sidepanel-right .sidepanel-toggle-container {
        left: -36px;
        right: 100%;
    }

    .sidepanel-content {
        font-size: 1rem;
    }

    .sidepanel-content h4 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .sidepanel-content a {
        text-decoration: none;
        font-size: 1rem;
        color: #199900;
        transition: color 0.3s ease-in;
    }

    .sidepanel-content a:hover {
        color: #116600;
        text-decoration: underline;
    }


    /*** Markers used on maps ***/
    .marker-icon::before {
        font-weight: bold;
        font-size: 18px;
        margin-top: -2px;
        margin-left: 3px;
        display:inline-block
    }

    .marker-icon-fa::before {
        font-weight: bold;
        font-size: 16px;
        margin-top: -1px;
        margin-left: 1px;
        display:inline-block
    }

    .marker-icon-bold::before {
        font-weight: bold;
        margin-top: 0px;
        display:inline-block
    }

    /* classes for Neighbouthood plan */

    .np-footnote {
        font-size: 0.8em;
        color: #6c757d;
    }

    .np-footnote a {
    text-decoration: none;
    color: #6c757d;
    }

    .no-footnote a:hover {
    color: #495057;
    }

    .np-superscript {
        vertical-align: super;
        font-size: 10pt;
    }

    .np-table-border {
        border: 3px solid black; /* Adjust thickness and color as needed */
    }

    .np-ref-list ol {
        list-style-type: none;
        counter-reset: np-ref-counter;
    }
    
    .np-ref-list li::before {
        content: "Ref: " counter(np-ref-counter) ": "; /* Use "Ref " + counter value + ": " as custom text */
        counter-increment: np-ref-counter;
        font-weight: bold;
    }